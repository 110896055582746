import React from 'react';
import { Button, Card, Container, Row, Col, Text, Icon } from '@gravity-ui/uikit';
import {Link} from '@gravity-ui/icons';

function ProductCard({ HeaderText, ButtonLink, ButtonText, Description, }) {   
    return (
        <Card theme="normal" style={{margin: 10}}>
            <Container style={{marginTop: 10, marginBottom: 10}}>
                <Row>
                    <Col>
                        <Text variant='header-1'>{HeaderText}</Text>
                    </Col>
                </Row>
                <Row style={{marginTop: 10}}>
                    <Col>
                        <Text variant='body-2'>{Description}</Text>
                    </Col>
                </Row>
                <Row style={{marginTop: 10}}>
                    <Col>
                        <Button size='l' view="action" href={ButtonLink} >
                            {ButtonText} 
                            <Icon data={Link} />
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Card>
    );
  }
  
  export default ProductCard;