import '@gravity-ui/uikit/styles/fonts.css';
import '@gravity-ui/uikit/styles/styles.css';
import {createRoot} from 'react-dom/client';
import {ThemeProvider} from '@gravity-ui/uikit';
import App from './App';

const root = createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme="dark">
    <App />
  </ThemeProvider>,
);

