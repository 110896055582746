import React from 'react';
import { Container, Row, Col } from '@gravity-ui/uikit';
import ProductCard from './for_main/ProductCard';
import TextBlock from './for_main/TextBlock';
import products from './../data/products.json'
import content from './../data/content.json'

function Main() {   
    
    return (
        <Container style={{justifyContent: 'center', alignItems: 'center'}}>
            <Row>
                <Col>
                    <TextBlock content={content.greating}/>
                </Col>
            </Row>
            <Row style={{justifyItems: 'center', justifyContent: 'center'}}>
                {products.map((item, index) => (
                    <Col s={12} m={8} l={6}>    
                        <ProductCard
                        key={index}
                        HeaderText={item.Header}
                        Description={item.Description}
                        ButtonLink={item.ButtonLink}
                        ButtonText={item.ButtonText}
                        />
                        </Col>
                ))}
            </Row>
            <Row>
                <Col>
                    <TextBlock content={content.goodbye}/>
                </Col>
            </Row>
        </Container>
    );
  }
  
  export default Main;












