import React from 'react';
import Main from './сomponents/Main';
import Footer from './сomponents/Footer';
import Header from './сomponents/Header';
import { Container } from '@gravity-ui/uikit';
import Banner from './сomponents/Banner';

function App() {
  return (
    <Container maxWidth="l" spaceRow={5} style={{marginTop: 20}}>
      <Header />
      <Banner />
      <Main />
      <Footer />
    </Container>
  );
}

export default App;