import React from 'react';
import { Card, Text } from '@gravity-ui/uikit';

function TextBlock({ content }) {   
    return (
        <Card theme="normal" view='clear' style={{margin: 5}}>
            <Text variant='body-2'>
                {content}
            </Text>   
        </Card>
    );
  }
  
  export default TextBlock;