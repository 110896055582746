import React from 'react';
import { Container, Row, Col, Text } from '@gravity-ui/uikit';

function Footer() {   
    return (
        <Container style={{marginTop: 15}}>
           <Row>
                <Col s={12} m={6}>
                    <Text variant='subheader-1'>&#169; {new Date().getFullYear()} Ideas Factory</Text> 
                </Col>
            </Row>
        </Container>
    );
  }
  
  export default Footer;