import React from 'react';
import { Container, Row, Col, Card, Text, Tabs, Forms } from '@gravity-ui/uikit';
import backImage from './../img/main_banner.png';

function Banner() {
  const cardStyles = {
    height: 200,
    backgroundImage: 'url(' + backImage + ')',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '8px', // Установите это значение в соответствии со значением border-radius для компонента Card
  };

  return (
    <Container style={{ marginTop: 10, marginBottom: 10 }}>
      <Row>
        <Col s={12}>
          <Card style={cardStyles} />
        </Col>
      </Row>
    </Container>
  );
}

export default Banner;

