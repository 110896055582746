import React from 'react';
import { Container, Row, Col, Text } from '@gravity-ui/uikit';

function Header() {   
    return (
        <Container style={{marginBottom: 15}}>
            <Row>
                <Col s={12} m={6}>
                    <Row><Text variant='display-2'>idFactory</Text></Row>
                    <Row><Text>ideas factory</Text></Row>      
                </Col>
            </Row>
        </Container>
    );
  }
  
  export default Header;